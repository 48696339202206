<template>
  <div id="AjoutFaq">
    <v-container>
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-row style="margin-top: 20px">
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
        <v-col cols="12" md="12">
          <v-card class="pa-3" outlined tile>
            <v-col class="text-right" style="margin-top: 60px">
              <v-btn tile color="primary" @click="dialogFormAjout = true">
                <v-icon left> mdi-plus-thick </v-icon>
                Questions/Réponses
              </v-btn>
            </v-col>
            <v-card>
              <v-card-title>Liste FAQ</v-card-title>
              <v-data-table
                :footer-props="{
                  'items-per-page-options': [5, 10, 15, 30],
                }"
                :headers="headers"
                :items="faqs"
                :search="search"
                :server-items-length="totalFaqCount"
                :options.sync="options"
                :loading="loading"
                class="elevation-1"
                calculate-widths
              >
                <template v-slot:item.edit="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="redirectPopup(item)"
                    style="margin-left: 10px"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <template v-slot:item.suppression="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="DeleteItem(item)"
                    style="margin-left: 20px"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
            <!-- Ajout FAQ -->
            <v-row justify="center">
              <v-dialog v-model="dialogFormAjout" persistent max-width="600px">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Nouvelle Question/Réponse</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              label="Ordre"
                              type="number"
                              v-model="faqNumber"
                              :min="totalFaqCount"
                              required
                              :rules="rulesChamp"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-text-field
                              label="Question"
                              required
                              clearable
                              v-model="question"
                              :rules="rulesChamp"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="12">
                            <v-textarea
                              :rules="rulesChamp"
                              v-model="reponse"
                              no-resize
                              clearable
                              required
                              label="Réponse"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="annulerAdd()">
                        Annuler
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        :loading="loading"
                        text
                        @click="validate()"
                      >
                        Ajouter
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
              <!-- confirmation d'ajout -->

              <v-dialog
                v-model="dialogConfirmationAjout"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-text>
                    Etes vous sur de vouloir ajouter la nouvelle
                    question/réponse ?</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary darken-1"
                      text
                      @click="dialogConfirmationAjout = false"
                    >
                      Annuler
                    </v-btn>
                    <v-btn
                      color="primary darken-1"
                      text
                      :loading="loading"
                      @click="addFaq"
                    >
                      Confirmer
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- confirmation de suppression -->

              <v-dialog
                v-model="dialogConfirmationSuppression"
                persistent
                max-width="290"
              >
                <v-card>
                  <v-card-text
                    >Etes vous sur de vouloir supprimer la FAQ ?</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click="closeDelete()">
                      Annuler
                    </v-btn>
                    <v-btn
                      color="primary darken-1"
                      text
                      :loading="loading"
                      @click="deleteFaqConfirm()"
                    >
                      Confirmer
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <EditFaq
                :EditFaqDialog.sync="EditFaqDialog"
                :Faq="sendFaqInfo"
                :TotalFaqCount="totalFaqCount"
                @Refrech="refreshTable()"
                @CloseEditFaqDialog="closeEditFaqDialog()"
              />
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
import FirebaseDB from "../../utils/firebaseDB";
import FunctionsUtils from "./../../utils/functions";
import firebaseForAuth from "firebase";
import EditFaq from "../components/core/EditFaq.vue";
export default {
  name: "AjoutFaq",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
    EditFaq,
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
      totalFaq: [],
      totalFaqCount: 0,
      totalFaq: [],
      faqs: [],
      lastVisibleFaq: null,
      loading: true,
      length: 5,
      search: "",
      test: null,
      options: {},
      uniqueId: null,
      question: null,
      reponse: null,
      ordre: null,
      dialogFormAjout: false,
      valid: true,
      rulesChamp: [(v) => !!v || "Champ obligatoire"],
      faqNumber: null,
      dialogConfirmationAjout: false,
      dialogFormModif: false,
      sendFaqInfo: [],
      EditFaqDialog: false,
      dialogConfirmationSuppression: false,
      deletedBy: null,
      itemDeleted: [],
      Index: null,
    };
  },

  methods: {
    // Fermeture de la popup modification FAQ au clique sur "Annuler"
    closeEditFaqDialog() {
      this.EditFaqDialog = false;
    },
    // Actialisation de la liste FAQ aprés modification d'une FAQ
    refreshTable() {
      this.EditFaqDialog = false;
      this.faqs = [];
      this.totalFaq = [];
      this.lastVisibleFaq = null;
      this.getListFaq();
    },
    // Redirection vers la popup Modification FAQ
    redirectPopup(item) {
      this.sendFaqInfo = item;
      this.EditFaqDialog = true;
    },
    /* Verifie si le formulaire d'ajout est bien remplie
    avant l'affichage de la boite de dialogue de confirmation d'ajout*/
    validate() {
      if (this.$refs.form.validate()) {
        this.dialogConfirmationAjout = true;
      }
    },
    annulerAdd() {
      this.dialogFormAjout = false;
      this.$refs.form.reset();
    },
    DeleteItem(item) {
      this.itemDeleted = item;
      this.Index = this.faqs.indexOf(item);
      this.dialogConfirmationSuppression = true;
    },
    closeDelete() {
      this.dialogConfirmationSuppression = false;
      this.itemDeleted = [];
      this.deletedBy = null;
    },
    deleteFaqConfirm() {
      this.loading = true;
      this.deletedBy = firebaseForAuth.auth().currentUser.uid;
      db.collection(NodesName.FREQUENTLY_ASKED_QUESTIONS)
        .doc(this.itemDeleted.uniqueId)
        .update({
          deleted: true,
          deletedBy: this.deletedBy,
          deletedDate: new Date(),
        })
        .then(() => {
          this.faqs.splice(this.Index, 1);
          this.uniqueId = null;
          this.dialogConfirmationSuppression = false;
          this.deletedBy = null;
          this.loading = false;
          this.totalFaqCount = this.faqs.length;
        });
    },
    // Ajout d'une nouvelle FAQ
    addFaq() {
      this.loading = true;
      var faqRef = db.collection(NodesName.FREQUENTLY_ASKED_QUESTIONS).doc();
      const faqQuestion = {
        orderFaq: Number(this.faqNumber),
        titleFaq: this.question,
        descFaq: this.reponse,
        uniqueId: faqRef.id,
        deleted: false,
        creationDate: new Date(),
        deletedBy: null,
        createdBy: firebaseForAuth.auth().currentUser.uid,
        deletedDate: null,
      };
      faqRef.set(faqQuestion).then(() => {
        this.faqs.push(faqQuestion);
        this.totalFaqCount += 1;
        this.dialogFormAjout = false;
        this.dialogConfirmationAjout = false;
        this.$refs.form.reset();
        this.loading = false;
      });
    },
    // Récuperation list FAQ
    async getListFaq() {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var query = db.collection(NodesName.FREQUENTLY_ASKED_QUESTIONS);

      this.totalFaqCount = await FirebaseDB.getCount(query);

      if (this.totalFaq.length < this.totalFaqCount) {
        var querySnapshot = await FirebaseDB.getFaqWithPagination(
          query,
          this.lastVisibleFaq,
          itemsPerPage,
          this.totalFaqCount
        );
        let i = 0;

        querySnapshot.forEach((faq) => {
          if (Object.entries(faq.data()).length !== 0) {
            const found = this.totalFaq.some(
              (el) => el.uniqueId === faq.uniqueId
            );

            if (!found) {
              this.totalFaq.push({
                uniqueId: faq.data().uniqueId,
                titleFaq: faq.data().titleFaq,
                descFaq: faq.data().descFaq,
                orderFaq: faq.data().orderFaq,
                edit: "",
                suppression: "",
              });

              i++;

              if (querySnapshot.size == i) {
                this.lastVisibleFaq = faq;
              }
            }
          }
        });
      }

      this.faqs = FunctionsUtils.customSortDataTable(
        this.totalFaq,
        sortBy,
        sortDesc
      );

      this.faqs = FunctionsUtils.getItemsPerPageForDataTable(
        this.totalFaq,
        itemsPerPage,
        page,
        this.totalFaqCountCount
      );

      this.loading = false;
    },
  },
  computed: {
    headers() {
      return [
        { text: "Ordre", value: "orderFaq" },
        { text: "Question", value: "titleFaq" },
        { text: "Réponse", value: "descFaq" },
        { text: "Modifier", value: "edit" },
        { text: "Suppression", value: "suppression" },
      ];
    },
  },
  async creates() {},
  watch: {
    options: {
      handler() {
        this.getListFaq();
      },
      deep: true,
    },
  },
};
</script>
