<template>
  <v-layout row justify-center>
    <v-dialog v-model="EditFaqDialog" persistent max-width="600">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">Modification Question/Réponse</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Ordre"
                    type="number"
                    min="0"
                    v-model="Faq.orderFaq"
                    required
                    :rules="rulesChamp"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    label="Question"
                    required
                    clearable
                    v-model="Faq.titleFaq"
                    :rules="rulesChamp"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    :rules="rulesChamp"
                    v-model="Faq.descFaq"
                    no-resize
                    clearable
                    required
                    label="Réponse"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditFaqDialog()">
              Annuler
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              @click="validate()"
            >
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Dialog confirmation modification FAQ -->
    <v-dialog v-model="dialogModification" persistent max-width="290">
      <v-card>
        <v-card-text
          >Etes vous sur de vouloir appliquer les modifications ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="dialogModification = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            :loading="loading"
            @click="updateFaq()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import NodesName from "../../../utils/nodesName";
import firebaseForAuth from "firebase";
import db from "../../../../firebaseInit";

export default {
  name: "EditFaq",
  data() {
    return {
      rulesChamp: [(v) => !!v || "Champ obligatoire"],
      valid: true,
      loading: false,
      dialogModification: false,
      existe: false,
    };
  },

  methods: {
    /* Verifie si le formulaire d'ajout est bien remplie 
    avant l'affichage de la boite de dialogue de confirmation de modification*/
    validate() {
      if (this.$refs.form.validate()) {
        this.dialogModification = true;
      }
    },
    //Actualisation du tableau aprés modification d'une FAQ
    refreshDataTable() {
      this.$emit("Refrech");
    },
    //Fermeture de la boite de dialogue modification apres annulation
    closeEditFaqDialog() {
      this.$emit("CloseEditFaqDialog");
    },
    //Requette de mise à jour d'une FAQ
    updateFaq() {
      this.loading = true;
      db.collection(NodesName.FREQUENTLY_ASKED_QUESTIONS)
        .doc(this.Faq.uniqueId)
        .update({
          orderFaq: Number(this.Faq.orderFaq),
          titleFaq: this.Faq.titleFaq,
          descFaq: this.Faq.descFaq,
          updatedDate: new Date(),
          updateBy: firebaseForAuth.auth().currentUser.uid,
        })
        .then(() => {
          this.dialogModification = false;
          this.refreshDataTable();
          this.loading = false;
        });
    },
  },

  props: {
    Faq: {
      required: true,
    },
    EditFaqDialog: {
      type: Boolean,
    },
    TotalFaqCount: {
      required: true,
    },
  },
};
</script>
